.page__wrapper .about {
  display: flex;
  margin: 160px auto 0;
  padding: 0 46px;
  max-width: 1440px;
}

@media screen and (max-width: 1200px) {
  .page__wrapper .about.wrapper {
    margin: 100px auto 0;
  }
}

@media screen and (max-width: 600px) {
  .page__wrapper .about.wrapper {
    margin: 30px auto 0;
  }
}

.about__sideTextContainer {
  align-self: end;
  margin: 0 85px 0 0;
  width: 19%;
}

@media screen and (max-width: 1000px) {
  .about__sideTextContainer {
    display: none;
  }
}

.about__sideTextTitle {
  margin: 0 0 10px;
  font: var(--font-h-l);
  font-weight: 700;
  font-size: 4.125rem;
}

@media screen and (max-width: 1400px) {
  .about__sideTextTitle {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 1200px) {
  .about__sideTextTitle {
    font-size: 3rem;
  }
}

.about__sideTextSubtitle {
  margin: 25px 0 0;
  font: var(--font-m);
}

.about__textContainer {
  margin: 0;
  width: 62%;

  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .about__textContainer {
    margin: 0 auto 0;
    width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .about__textContainer {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .about__textContainer {
    margin: 0 0 35px;
    width: 100%;
    line-height: 1.4;
  }
}

.about__title {
  margin: 0;
  color: rgba(231, 231, 231, 0.5);
  font: var(--font-m);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.22rem;
  text-transform: uppercase;
  white-space: nowrap;
}

@media screen and (max-width: 600px) {
  .about__title {
    display: none;
  }
}

.about__text {
  margin: 32px 0 0;
  font: var(--font-ml);
  font-size: 1.625rem;
  line-height: 1.4;
  white-space: pre-wrap;
  text-align: justify;
  text-justify: inter-word;
}

@media screen and (max-width: 1400px) {
  .about__text {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .about__text {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 600px) {
  .about__text {
    font: var(--font-h-m);
    font-weight: 300;
  }
}

/* animation controls */

.about__text.animation__text-invis {
  --delay: 1s;
}

@media screen and (max-width: 600px) {
  .about__text.animation__text-invis {
    --delay: 0s;
  }
}

.about.animation .animation__text-rise {
  animation-delay: 0.5s;
}

.about.animation .animation__text-rise .animation__wrapper {
  animation-delay: 0.5s;
}

/* end */
