.reviews {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 200px auto 0;
  padding: 20px;
  background-color: var(--primary-bg-color);
  justify-content: center;
  /* overflow: hidden; */
  /* max-height: 400px; */
  box-sizing: border-box;
  max-width: 1440px;
}

.reviews__title {
  margin: 0;
  font: var(--font-h-l);
}

.reviews__subtitle {
  margin: 24px 0 0;
  font: var(--font-ml);
}

@media screen and (max-width: 768px) {
  .reviews {
    padding: 0;
    margin: 120px auto 0;
  }
  .reviews__title {
    font-size: clamp(1rem, 8vw, 2.06rem);
  }

  .reviews__subtitle {
    margin: 16px 0 0;
    font: var(--font-m);
  }
}

.reviews__wrapper {
  overflow: hidden;
  padding: 0 20px; /* Добавляем боковые отступы */
  width: 95%;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .reviews__wrapper {
    max-width: 100vw;
    width: calc(100% - 50px);
    margin: 0 25px;
    padding: 0;
  }
}

.reviews__inner {
  display: flex;
  margin: 48px auto 0;
  gap: 20px;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 220px; /* Учитываем padding для snap */
  scroll-padding-right: 20px;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

  touch-action: pan-y;
  overflow-y: auto; /* Включаем только вертикальный скролл */
  overflow-x: hidden; /* Отключаем горизонтальный скролл */
  -webkit-overflow-scrolling: touch;
  /* touch-action: pan-x;
  touch-action: -ms-manipulation pan-x;
  touch-action: -webkit-manipulation pan-x; */
}

@media screen and (max-width: 768px) {
  .reviews__inner {
    margin: 36px auto 0;
    scroll-padding-left: 10px;
    scroll-padding-right: 10px;
    max-width: 100vw;
  }
}

.reviews__inner::-webkit-scrollbar {
  display: none;
}

.reviews__inner {
  scrollbar-width: none;
}

.reviews__card {
  position: relative;
  display: flex;
  margin-right: 20px;
  flex-direction: column;
  padding: 15px;
  border: 1px solid var(--color-dark-grey);
  border-radius: 8px;
  background-color: var(--color-dark-grey);
  width: 450px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  flex-shrink: 0;
  /* transition: width 0.3s ease, background-color 0.3s ease; */
  transition: background-color 0.3s ease;
  scroll-snap-align: center;
}

@media (max-width: 768px) {
  .reviews__card {
    margin-right: 0px;
    width: 80%;
    max-width: 768px;
    background-color: var(--color-black);
    box-sizing: border-box;

    /* transition: width 0.3s ease; */
  }
}

.reviews__card_active {
  /* width: 450px; */
  background-color: var(--color-black);
}

@media (max-width: 768px) {
  .reviews__card,
  .reviews__card_active {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 480px) {
  .reviews__card,
  .reviews__card_active {
    width: 100%;
  }
}

.reviews__blurhash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  transition: opacity .25s ease-out;
}
.reviews__card:hover .reviews__blurhash {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .reviews__blurhash {
    z-index: 0;
  }
}

.reviews__card_active .reviews__blurhash {
  z-index: 0;
}

.reviews__header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: var(--color-grey);
  margin-bottom: 10px;
  z-index: 1;
}
.reviews__src {
  color: var(--color-grey);
  transition: color .25s ease-out;
}
.reviews__src:hover {
  color: var(--color-l-grey);
}

.reviews__date {
  color: var(--color-m-grey);
}

.reviews__text {
  font: var(--font-m);
  margin-bottom: 15px;
  line-height: 1.5;
  color: var(--color-white);
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
  text-align: justify;
  text-justify: inter-word;
}

.reviews__read-more {
  cursor: pointer;
  z-index: 1;
  color: var(--color-grey);
  transition: color .25s   ease-out;
}

.reviews__read-more:hover {
  color: var(--color-l-grey);
}

.reviews__footer {
  display: flex;
  align-items: center;
  margin-top: 50px;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .reviews__footer {
    margin-top: 30px;
  }
}

.reviews__avatar {
  border-radius: 50%;
  margin-right: 10px;
}

.reviews__info {
  display: flex;
  flex-direction: column;
}

.reviews__name {
  font: var(--font-ml);
  color: var(--color-white);
  margin: 0;
}

.reviews__project {
  font: var(--font-s);
  color: var(--color-grey);
  margin: 0;
}

.reviews__indexContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.reviews__arrow {
  width: auto;
  height: auto;
  cursor: pointer;
  height: 16px;
}

.reviews__arrow_inactive {
  opacity: 0.5;
  cursor: default;
  height: 12px;
}

.reviews__arrow_left {
  margin-right: 10px;
}

.reviews__arrow_right {
  margin-left: 10px;
}

.reviews__indexItem {
  font: var(--font-s);
  color: var(--color-white);
  margin: 0 5px;
}

.reviews__indexLine {
  width: 20px;
  border: none;
  border-top: 1px solid var(--color-grey);
}

.reviews__controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin: 50px auto 0;
}

.reviews__progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  margin: 0 25px;
}


.reviews__progress-info {
  font: var(--font-ml);
  color: var(--color-white);
  margin-bottom: 5px;
}

.reviews__progress-bar {
  width: 100%;
  height: 4px;
  background-color: var(--color-dark-grey);
  border-radius: 2px;
  overflow: hidden;
  box-sizing: border-box;
}

.reviews__progress-thumb {
  height: 100%;
  background-color: var(--color-accent);
  transition: width 0.3s ease;
}

.reviews__arrow {
  font: var(--font-ml);
  color: var(--color-accent);
  cursor: pointer;
  background-color: transparent;
  width: auto;
  height: 2rem;
}

.reviews__arrow_inactive {
  font-size: 16px;
  color: var(--color-grey);
  cursor: default;
}
